import { Application } from "@hotwired/stimulus";
(window as any).Stimulus = Application.start();

import FlashController from "../../controllers/flash_controller";

const controllers = {
  flash: FlashController,
};

for (const [name, controller] of Object.entries(controllers)) {
  // @ts-ignore
  Stimulus.register(name, controller);
}
